<template>
  <div class="card-body position-relative overflow-hidden">
    <a-col v-if="loading" :span="16" :offset="11">
      <a-spin size="large" />
    </a-col>

    <div v-else>
      <div
        role="button"
        class="font-size-36 font-weight-bold text-dark mb-n2 clickable"
        @click="openDetails"
      >
        {{ count }}
        <span class="font-size-18"> {{ $t("accueil.classes") }}</span>
      </div>

      <a-tooltip :title="$t('accueil.moreDetails')">
        <a-button icon="eye" @click="openDetails">
          {{ $t("sms.deatilsAutoSms") }}
        </a-button>
      </a-tooltip>
      <div :class="$style.chartContainer">
        <vue-chartist
          :class="$style.chart"
          class="ct-hidden-points"
          :data="chartData"
          :options="chartOptions"
          type="Line"
        />
      </div>
    </div>

    <a-modal
      :visible="detailsVisible"
      :title="$t('accueil.stat')"
      @cancel="closeDetails"
      width="70%"
      :footer="null"
    >
      <a-tabs v-model="activeTab" default-active-key="niveaux">
        <a-tab-pane key="niveaux" :tab="$t('personnel.niveau')">
          <a-table
            :columns="niveauColumns"
            :data-source="niveauxWithTotal"
            :pagination="true"
            :rowClassName="setRowClass"
            bordered
          >
            <template slot="niveau" slot-scope="text, record">
              {{ record.isTotal ? record.level : getLevelName(record.level) }}
            </template>
          </a-table>
        </a-tab-pane>

        <a-tab-pane key="classes" :tab="$t('accueil.classes')">
          <a-table
            :key="tableKey"
            :columns="classColumns"
            :data-source="classesWithTotal"
            :pagination="true"
            :rowClassName="setRowClass"
            bordered
          >
            <template
              slot="filterDropdown"
              slot-scope="{
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
                column,
              }"
              style="padding: 8px"
            >
              <a-input
                v-ant-ref="(c) => (searchInput = c)"
                :placeholder="`${$t('personnel.chercher')} ${column.title}`"
                :value="selectedKeys[0]"
                style="width: 188px; margin-bottom: 8px; display: block"
                @change="
                  (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
                "
                @pressEnter="
                  () => handleSearch(selectedKeys, confirm, column.dataIndex)
                "
              />
              <a-button
                type="primary"
                icon="search"
                size="small"
                style="width: 90px; margin-right: 8px"
                @click="
                  () => handleSearch(selectedKeys, confirm, column.dataIndex)
                "
              >
                {{ $t("recette.chercher") }}
              </a-button>
              <a-button
                size="small"
                style="width: 90px"
                @click="() => handleReset(clearFilters)"
              >
                {{ $t("action.reinitialiser") }}
              </a-button>
            </template>

            <template slot="filterIcon" slot-scope="filtered">
              <a-icon
                type="search"
                :style="{ color: filtered ? '#108ee9' : undefined }"
              />
            </template>

            <template slot="customRender" slot-scope="text, record">
              {{ record.isTotal ? $t("liste.total") : text }}
              <a-tag
                color="red"
                class="ml-2"
                v-if="record.status == 'inactive'"
                >{{ $t("accueil.deletedClasse") }}</a-tag
              >
            </template>
          </a-table>
        </a-tab-pane>
      </a-tabs>
    </a-modal>
  </div>
</template>

<script>
import apiClient from "@/services/axios";
import VueChartist from "v-chartist";
import ChartistTooltip from "chartist-plugin-tooltips-updated";
import { mapState } from "vuex";

export default {
  name: "KitChart11v1",
  components: {
    "vue-chartist": VueChartist,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    count: {
      required: true,
    },
  },
  data() {
    return {
      searchInput: null,
      chartOptions: {
        axisX: { showLabel: false, showGrid: false, offset: 0 },
        axisY: { showLabel: false, showGrid: false, offset: 0 },
        showArea: true,
        high: { top: 0, right: 0, bottom: 0, left: 0 },
        chartPadding: { top: 0, right: 0, bottom: 0, left: 0 },
        fullWidth: true,
        height: "110px",
        showPoint: false,
        plugins: [
          ChartistTooltip({
            anchorToPoint: false,
            appendToBody: true,
            seriesName: false,
          }),
        ],
      },
      chartData: {
        series: [[42, 80, 67, 84, 74, 82, 64]],
      },
      detailsVisible: false,
      niveaux: [],
      classes: [],
      totalBoys: 0,
      totalGirls: 0,
      totalStudents: 0,
      activeTab: "niveaux",
      tableKey: 0,
      defaultLevels: [-2, -1, 1, 2, 3, 4, 5, 6],
    };
  },
  created() {
    this.fetchDetailedStatistics();
  },
  computed: {
    ...mapState(["settings", "user"]),

    niveauColumns() {
      return [
        {
          title: this.$t("personnel.niveau"),
          dataIndex: "level",
          key: "niveau",
          scopedSlots: { customRender: "niveau" },
        },
        {
          title: this.$t("accueil.nombreDeClasses"),
          dataIndex: "classCount",
          key: "classCount",
        },
        {
          title: this.$t("paiement.nbEleves"),
          children: [
            {
              title: this.$t("accueil.boys"),
              dataIndex: "boyCount",
              key: "boyCount",
            },
            {
              title: this.$t("accueil.girls"),
              dataIndex: "girlCount",
              key: "girlCount",
            },
            {
              title: this.$t("liste.total"),
              dataIndex: "studentCount",
              key: "studentCount",
            },
          ],
        },
      ];
    },

    classColumns() {
      return [
        {
          title: this.$t("accueil.classes"),
          dataIndex: "name",
          key: "name",
          scopedSlots: {
            customRender: "customRender",
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          onFilter: (value, record) =>
            record.name.toString().toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              }, 0);
            }
          },
        },
        {
          title: this.$t("paiement.nbEleves"),
          children: [
            {
              title: this.$t("accueil.boys"),
              dataIndex: "boys",
              key: "boys",
            },
            {
              title: this.$t("accueil.girls"),
              dataIndex: "girls",
              key: "girls",
            },
            {
              title: this.$t("liste.total"),
              dataIndex: "studentCount",
              key: "studentCount",
            },
          ],
        },
      ];
    },

    niveauxWithTotal() {
      const completeLevels = this.defaultLevels.map((defaultLevel) => {
        const defaultLevelStr = defaultLevel.toString();

        const existingLevel = this.niveaux.find(
          (n) => n.level.toString() === defaultLevelStr
        );

        if (existingLevel) {
          return existingLevel;
        }

        return {
          level: defaultLevel,
          classCount: 0,
          boyCount: 0,
          girlCount: 0,
          studentCount: 0,
        };
      });

      const totalRow = {
        level: this.$t("liste.total"),
        classCount: this.niveaux.reduce(
          (sum, level) => sum + level.classCount,
          0
        ),
        boyCount: this.totalBoys,
        girlCount: this.totalGirls,
        studentCount: this.totalStudents,
        isTotal: true,
      };

      return [...completeLevels, totalRow];
    },

    classesWithTotal() {
      if (!this.classes.length) return [];

      const totalRow = {
        name: this.$t("liste.total"),
        boys: this.totalBoys,
        girls: this.totalGirls,
        studentCount: this.totalStudents,
        isTotal: true,
      };

      return [...this.classes, totalRow];
    },
  },
  methods: {
    setRowClass(record) {
      return record.isTotal ? "bg-gray-2 font-weight-bold" : "";
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },
    handleReset(clearFilters) {
      clearFilters();
      this.searchText = "";
    },
    getLevelName(level) {
      return (
        {
          "-2": this.$t("niveau.niveauPre"),
          "-1": this.$t("niveau.niveau0"),
          1: this.$t("niveau.niveau1"),
          2: this.$t("niveau.niveau2"),
          3: this.$t("niveau.niveau3"),
          4: this.$t("niveau.niveau4"),
          5: this.$t("niveau.niveau5"),
          6: this.$t("niveau.niveau6"),
        }[level] || level
      );
    },

    openDetails() {
      this.detailsVisible = true;
      this.fetchDetailedStatistics();
    },
    closeDetails() {
      this.detailsVisible = false;
      this.activeTab = "niveaux";
      this.searchText = "";
      this.searchedColumn = "";
      this.searchInput = null;
      this.tableKey += 1;
    },
    fetchDetailedStatistics() {
      apiClient
        .get("/home/dashboard", {})
        .then((response) => {
          this.classes = response.data.classes;
          this.niveaux = response.data.niveaux;
          this.totalStudents = response.data.totalStudents;
          this.totalBoys = response.data.totalBoys;
          this.totalGirls = response.data.totalGirls;
        })
        .catch((error) => {
          console.error("Error fetching dashboard data", error);
        });
    },
  },
};
</script>

<style lang="scss" module>
@import "./style.module.scss";

.clickable {
  cursor: pointer;
  transition: color 0.2s ease, transform 0.2s ease;
}

.clickable:hover {
  color: #007bff;
  text-decoration: underline;
  transform: scale(1.02);
}

.niveau-item {
  margin-bottom: 10px;
}

.class-item {
  margin-bottom: 15px;
}

.totals {
  margin-top: 20px;
  font-weight: bold;
}
</style>
